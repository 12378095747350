import { useFetch, useNuxtApp } from 'nuxt/app'
const { $bus, $sentry } = useNuxtApp();

type FetchOptions = {
  baseURL: string
  headers: {
    Authorization?: string
    'Content-Type'?: string
    Accept?: string
  }
  params?: any
  body?: Object
}

export function useApi() {
  const config = useRuntimeConfig()

  async function fetchWithAuth<T>(
    url: string,
    request: 'GET' | 'POST' | 'DELETE' | 'PUT',
    options?: {
      data?: Object
      providedHeaders?: Object
      providedOptions?: Object
      defaultOptions?: Object
    }
  ) {
    const keycloakStore = useKeycloak()

    return await fetch<T>(url, request, options, keycloakStore.keycloak?.token)
  }

  async function fetch<T>(
    url: string,
    request: 'GET' | 'POST' | 'DELETE' | 'PUT',
    options?: {
      data?: Object
      providedHeaders?: Object
      providedOptions?: Object
      defaultOptions?: Object
    },
    bearerToken?: string
  ) {
    let fetchOptions: FetchOptions = {
      baseURL: config.public.apiKey,
      headers: {
        ...options?.providedHeaders,
        ...(bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {}),
        Accept: 'application/json, text/plain, */*',
      },
      params: {
        ...options?.defaultOptions,
        ...options?.providedOptions,
      },
    }

    switch (request) {
      case 'DELETE':
        break
      case 'POST':
        // fetchOptions.headers["Content-Type"] = "application/json";
        fetchOptions.body = options?.data
        fetchOptions.params = {
          ...options?.defaultOptions,
          ...options?.providedOptions,
        }
        break
      case 'PUT':
        // fetchOptions.headers["Content-Type"] = "application/json";
        fetchOptions.body = options?.data
        fetchOptions.params = {
          ...options?.defaultOptions,
          ...options?.providedOptions,
        }
        break
      default:
        break
    }

    // server error codes
    const errorCodes = [500, 502, 503, 504]

    try {
      const response = await $fetch<T>(url, {
        method: request,
        ...fetchOptions,
      })
      return response
    } catch (error) {
      if (errorCodes.some(e => e === error?.statusCode)) {
        // remove show error because API often returns status code 500
        // showError({
        //   statusCode: error?.statusCode,
        // })

        $bus.emit('notification', {
          message: 'Internal Server Error. Please try again later.',
          type: 'error',
        })
        $sentry.captureException(error)
        createError(error)
      } else {
        throw createError(error)
      }
    }
  }

  return { fetchWithAuth, fetch }
}
